// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-kb-item-js": () => import("/opt/build/repo/src/templates/kb-item.js" /* webpackChunkName: "component---src-templates-kb-item-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-video-tag-page-js": () => import("/opt/build/repo/src/templates/video-tag-page.js" /* webpackChunkName: "component---src-templates-video-tag-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-video-tags-js": () => import("/opt/build/repo/src/pages/video-tags.js" /* webpackChunkName: "component---src-pages-video-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kb-js": () => import("/opt/build/repo/src/pages/kb.js" /* webpackChunkName: "component---src-pages-kb-js" */),
  "component---src-pages-kb-tags-js": () => import("/opt/build/repo/src/pages/kb/tags.js" /* webpackChunkName: "component---src-pages-kb-tags-js" */),
  "component---src-pages-newsletter-js": () => import("/opt/build/repo/src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-gatsby-post-manager-js": () => import("/opt/build/repo/src/pages/projects/gatsby-post-manager.js" /* webpackChunkName: "component---src-pages-projects-gatsby-post-manager-js" */),
  "component---src-pages-projects-graphqlstack-js": () => import("/opt/build/repo/src/pages/projects/graphqlstack.js" /* webpackChunkName: "component---src-pages-projects-graphqlstack-js" */),
  "component---src-pages-projects-react-timeline-js": () => import("/opt/build/repo/src/pages/projects/react-timeline.js" /* webpackChunkName: "component---src-pages-projects-react-timeline-js" */),
  "component---src-pages-tags-js": () => import("/opt/build/repo/src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-videos-js": () => import("/opt/build/repo/src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-work-js": () => import("/opt/build/repo/src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

